$(document).foundation();

(function ($) {

    var direction = $('html').css("direction").toLowerCase();
    var direction_opposite = $('html').css("direction").toLowerCase() === 'rtl' ? 'left' : 'right';
    var direction_boolean = $('html').css("direction").toLowerCase() === 'rtl';

    // var elementToStick = $('.site-header');
    // var elementToStickHeight = elementToStick.height();
    // var stickyHeader = $('#sticky-header');
    // var AreaToStickHeight;
    // var leaderTimeout;

    // if ($("#sticky-header #leaderboard").length) {
    //     stickyHeader.addClass('stuck-header-ad');
    // }

    // var waypoint = new Waypoint({
    //     element: document.getElementById('sticky-header-waypoint'),
    //     handler: function (direction) {
    //         AreaToStickHeight = stickyHeader.height();
    //         if ($("#sticky-header #leaderboard").length) {
    //             stickyHeader.toggleClass('stuck-header-ad');
    //         }
    //         console.log(direction, 'direction');

    //         stickyHeader.toggleClass('stuck-header');
    //         if (direction == "down") {
    //             elementToStick.find('.top-header, .bottom-header').appendTo(stickyHeader);
    //             $('.sticky-header-spacer').css({ "visibility": "visible", "display": "block", "height": elementToStickHeight });

    //             if ($("#leaderboard").length) {
    //                 //hide ads after scrolldown
    //                 var stickyTopDuration = $("#leaderboard").data("duration") * 1000;
    //                 leaderTimeout = setTimeout(function () {
    //                     $("#leaderboard").slideUp("fast"); //hide leaderboard after 3 seconds 
    //                 }, stickyTopDuration);
    //             }

    //         }
    //         if (direction == "up") {  
    //             stickyHeader.find('.top-header, .bottom-header').appendTo(elementToStick);
    //             $('.sticky-header-spacer').css({ "visibility": "hidden", "display": "none" });

    //              //on scroll default position show leaderboard
    //              if ($("#leaderboard").length) {
    //                 clearTimeout(leaderTimeout);
    //                 // mainHeader.prepend(leaderBoard);
    //                 $("#leaderboard").show();
    //             }
                
    //         }
    //     },
    //     offset: function () {
    //         return -this.element.clientHeight
    //     }
    // });


    var leaderTimeout;
     $("#sticky-header-waypoint").hcSticky({
        stickTo: 'body',
        onStart: function () {
            console.log("start")
            $(".top-header").hide();
            $(".bottom-header").addClass("sticky");

            // Leaderboard ads 
            if ($("#leaderboard").length && $("#leaderboard").is(":visible")) {
                var stickyTopDuration = $("#leaderboard").data("duration") * 1000;

                leaderTimeout = setTimeout(function () {
                    $("#leaderboard").slideUp("fast"); //hide leaderboard after 3 seconds  
                }, stickyTopDuration);
            }
        },
        onStop: function () {
            $(".top-header").show();
            $(".bottom-header").removeClass("sticky");

            if ($("#leaderboard").length) {
                clearTimeout(leaderTimeout);
                $("#leaderboard").slideDown("fast");
            }
        }
    });


    $(".use-loader").each(function (index) {
        //console.log( index + ": " + $( this ).text() );
        var $this = $(this);
        $this.waitForImages({
            finished: function () {
                $this.addClass('slick-loaded');
            },
            waitForAll: true
        });
    });

    // Initialize Slidebars
    var controller = new slidebars();
    controller.init();

    $(document).on('click', '.toggle-nav-panel', function (event) {
        // Stop default action and bubbling
        event.stopPropagation();
        event.preventDefault();

        // Toggle the Slidebar with id 'id-4'
        controller.toggle('nav-panel');
    });

    // Add close class to canvas container when Slidebar is opening
    $(controller.events).on('opening', function (event) {
        $('[canvas="container"]').addClass('sb-opening');
    });

    // Add close class to canvas container when Slidebar is opening
    $(controller.events).on('opened', function (event) {
        $('[canvas="container"]').addClass('sb-opened');
    });

    // Remove close class from canvas container when Slidebar is closing
    $(controller.events).on('closing', function (event) {
        $('[canvas="container"]').removeClass('sb-opening');
    });

    // Remove close class from canvas container when Slidebar is closing
    $(controller.events).on('closed', function (event) {
        $('[canvas="container"]').removeClass('sb-opened');
    });

    // Close any
    $(document).on('click', '.sb-opening', function (event) {
        if (controller.getActiveSlidebar()) {
            event.preventDefault();
            event.stopPropagation();
            controller.close();
        }
    });



    // OTHER SCRIPTS
    var isOpen = false;
    var inputBox = $('.search-area').find('input[type="text"]');
    var searchBox = $('.searchboxform');
    var searchBtn = $('.search-btn');
    searchBtn.click(function (ev) {
        ev.stopPropagation();
        if (isOpen == false) {
            searchBtn.addClass('opened');
            $('.search-area').show();
            inputBox.focus();
            isOpen = true;
        } else {
            searchBtn.removeClass('opened');
            $('.search-area').hide();
            inputBox.focusout();
            isOpen = false;
        }
    });
    searchBtn.mouseup(function () {
        return false;
    });
    searchBox.mouseup(function () {
        return false;
    });

    $(document).mouseup(function () {
        if (isOpen == true) {
            searchBtn.removeClass('opened');
            $('.search-area').hide();
            inputBox.focusout();
            isOpen = false;
        }
    });

    $('.clear-btn').on('click', function () {
        $('.search-area').find('input[type="text"]').val('');
        inputBox.focus();
    });


    var itemsSlider = $('.items-slider');
    if (itemsSlider.length) {
        itemsSlider.slick({
            // slide: '.item-holder',
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            arrows: true,
            dots: false,
            rtl: direction_boolean,
        });
    }


    var hashValue;
    var mediaImgHeight;

    var mediaSlider = $('.media-slider');
    if (mediaSlider.length) {
        mediaSlider.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            swipe: false,
            rtl: direction_boolean,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        swipe: true
                    }
                }
            ]
        });
    }


    function fixCtrlPosition() {
        mediaImgHeight = $('.media-slider .slick-current img').height();
        $('.media-slider .slick-prev, .media-slider .slick-next').css({ 'top': (mediaImgHeight - 54) / 2 });
    }

    function scrollToSlider() {
        $("html, body").animate({ scrollTop: $('.entry-media').offset().top - 100 }, 2000, function () {
            fixCtrlPosition();
            $('.entry-media').removeClass('loader-area');
        });
    }

    mediaSlider.on('setPosition', function (slick) {
        fixCtrlPosition();
    });
    mediaSlider.on('afterChange', function (event, slick, currentSlide) {
        hashValue = $(slick.$slides.get(slick.currentSlide)).find('.slider-item').attr('data-hash');
        window.location.hash = 'slide/' + hashValue;
        //mediaImgHeight = $(slick.$slides.get(slick.currentSlide)).find('.slider-item-img').height();
        //$('.media-slider .slick-prev, .media-slider .slick-next').css('top',(mediaImgHeight-54)/2);
        call_google_analytics();
    });

    var urlHash = window.location.hash;
    if (urlHash && urlHash.indexOf('slide/') == 1) {
        urlHash = urlHash.split('#slide/')[1];
        $('.entry-media').addClass('loader-area');
        $('.media-slider-intro').fadeOut();
        mediaSlider.slick("slickGoTo", urlHash - 1);
        mediaSlider.fadeIn("slow", function () {
            scrollToSlider();
        });
    }

    $('.begin-slideshow').on('click', function (ev) {
        ev.preventDefault();
        window.location.hash = 'slide/' + 1;
        $('.entry-media').addClass('loader-area');
        $('.media-slider-intro').fadeOut();
        mediaSlider.fadeIn("slow", function () {
            mediaSlider.slick('setPosition');
            scrollToSlider();
        });
    });

    var slideshow = $('.slideshow');
    if (slideshow.length > 0) {
        slideshow.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            fade: true,
            autoplay: true,
            autoplaySpeed: 4000,
            lazyLoad: 'progressive',
            rtl: direction_boolean,
            customPaging: function (slick, index) {
                return '<i class="icomoon-icon icon-circle"></i>';
            },
            appendDots: $('.slideshow-pager')
        });
    }
    slideshow.on('setPosition', function (slick) {
        /*mediaImgHeight = $('.slideshow .slick-current img').height();
         $('.slideshow .slick-prev, .slideshow .slick-next').css({'top': (mediaImgHeight - 54) / 2});*/
    });

    var recipeMainSlider = $('.recipe-main-slider');
    var recipeThumbsSlider = $('.recipe-thumbs-slider');
    if (recipeMainSlider.length > 0) {
        recipeMainSlider.slick({
            arrows: false,
            dots: false,
            autoplay: false,
            asNavFor: '.recipe-thumbs-slider',
            adaptiveHeight: true,
            rtl: direction_boolean,
        });

        recipeThumbsSlider.slick({
            autoplay: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            asNavFor: '.recipe-main-slider',
            arrows: false,
            dots: false,
            focusOnSelect: true,
            rtl: direction_boolean,
        });
    }




    $(document).on('click', '.filter-tabs label', function () {
        var $this = $(this);
        $('.filter-tabs label').removeClass('active');
        $this.toggleClass('active');
        var mediaid = $this.attr('data-nid').toString();
        //alert(mediaid);
        // var content = '<div style="position:relative; padding-bottom:56.25%; overflow:hidden;"><iframe src="https://content.jwplatform.com/players/'+mediaid+'-0K3wdgz7.html" width="100%" height="100%" frameborder="0" scrolling="auto" allowfullscreen style="position:absolute;"></iframe></div>';
        $("#edit-field-recipe-category-tid").val(mediaid);
        $("#edit-field-recipe-category-tid").change();
        $('#edit-submit-food').click();

    });
})(jQuery);



function call_google_analytics() {
    var trackURL = jQuery(location).attr("pathname");
    if (trackURL != 'undefined') {
        try {
            trackClickView(trackURL);
        } catch (e) {
            console.log("failed to send a ping to GA");
        }

        try {
            refreshEM(trackURL);
        } catch (e) {
            console.log("failed to ping EM");
        }
    }
}
